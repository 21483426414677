import { useEffect, useRef, useState } from 'react'
import { Avatar, Brand, Grid, Text2 } from 'components/design-system'
import { ConfirmationModal } from 'components/common'
import * as SvgIcon from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import useStyle from './style'
import { useAuth } from 'navigation/ProvideAuth'
import { useTheme } from '@mui/styles'
import { Theme } from '@mui/material'
import { NavLink, useHistory } from 'react-router-dom'
import { useAtom } from 'jotai'
import { atomProfileTab } from 'pages/ProfileTabs/atomStore'
import {
  atomActiveMenuSidebar,
  atomCurrentActiveMenuSidebar,
  atomActiveHeaderConfigs,
  atomActiveModalVinculate
} from 'navigation/atomStore'
import { useStore } from 'store'
import { IProfilesSessionResponse, UserSchoolProfileTypeEnum } from 'services'
import { ROOT } from 'navigation/CONSTANTS'
import { VinculateModal } from './VinculateModal'
import { ChangeSchoolModal } from './ChangeSchoolModal'
import Analytics from 'utils/analytics'
import { useResetAtom, useUpdateAtom } from 'jotai/utils'
import { atomPreSelectedSchoolPeriod, atomSchoolPeriod } from 'pages/ClassManagement/atomStore'
import { useClassManagement, useTeacherManagement, useStudentManagement } from 'navigation/hooks'

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const Header: React.FC = () => {
  const auth = useAuth()
  const classes = useStyle()
  const history = useHistory()
  const theme = useTheme<Theme>()
  const { session, profile, setProfile, school, setSchool } = useStore()
  const { t } = useTranslation()
  const { resetTeacherFilters, resetTeacherLists } = useTeacherManagement()
  const { resetStudentFilters, resetStudentLists } = useStudentManagement()
  const { resetClassFilters } = useClassManagement()

  // atoms
  const [activeHeaderConfigs, setActiveHeaderConfigs] = useAtom(atomActiveHeaderConfigs)
  const [activeMenuSidebar, setActiveMenuSidebar] = useAtom(atomActiveMenuSidebar)
  const [activeModalVinculate, setActiveModalVinculate] = useAtom(atomActiveModalVinculate)

  const resetPreSelectedSchoolPeriod = useResetAtom(atomPreSelectedSchoolPeriod)
  const resetSchoolPeriod = useResetAtom(atomSchoolPeriod)

  const setCurrentActiveMenuSidebar = useUpdateAtom(atomCurrentActiveMenuSidebar)
  const setProfileTab = useUpdateAtom(atomProfileTab)

  // states
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [pageYOffset, setPageYOffset] = useState<number>(0)
  const [selectedProfile, setSelectedProfile] = useState<IProfilesSessionResponse | null>(profile)
  const [visible, setVisible] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState(false)

  const handleOpenModal = () => setOpenModal(!openModal)

  const handleCloseConfirmationModal = () => {
    setSelectedProfile(profile)
    setOpenConfirmationModal(false)
  }
  const handleOpenConfirmationModal = () => {
    if (profile?.id !== selectedProfile?.id) {
      setOpenModal(false)
      setOpenConfirmationModal(true)
    }
  }

  const redirectHomePage = () => {
    setProfileTab(0)
    setCurrentActiveMenuSidebar('home')
    history.push(ROOT)
  }

  const previousPageYOffset = usePrevious(pageYOffset) as number

  const handleScroll = () => {
    const isScrollToDown = pageYOffset > previousPageYOffset

    if (!isScrollToDown !== visible) {
      setVisible(!isScrollToDown)
    }
  }

  if (document.body.clientWidth < theme.breakpoints.values.sm) {
    document.removeEventListener('scroll', () => setPageYOffset(window.scrollY))
    document.addEventListener('scroll', () => setPageYOffset(window.scrollY))
  }

  useEffect(() => {
    handleScroll()
  }, [pageYOffset])

  const showVinculateModal = () => {
    setActiveModalVinculate(!activeModalVinculate)
  }

  const handleExit = async () => {
    setActiveHeaderConfigs(!activeHeaderConfigs)
    auth?.signout()
  }

  const handleSelectSchool = async () => {
    setIsLoading(true)
    const redirectToCloe = [UserSchoolProfileTypeEnum.teacher, UserSchoolProfileTypeEnum.student]
    if (!selectedProfile) return

    await auth?.updateLastUsedProfile(selectedProfile.id)

    if (!redirectToCloe.includes(selectedProfile?.type)) {
      resetStudentLists()
      resetTeacherLists()
      resetTeacherFilters()
      resetStudentFilters()
      resetClassFilters()
      resetPreSelectedSchoolPeriod()
      resetSchoolPeriod()
      setProfile(selectedProfile)
      setSchool(selectedProfile.school)
      setOpenConfirmationModal(false)
      redirectHomePage()

      return
    }

    if (profile) {
      Analytics.recordEventClick({
        name: 'change_profile',
        attributes: {
          ...profile?.analytics,
          current_school_id: profile?.school?.id,
          current_school_name: profile?.school?.name,
          selected_school_id: selectedProfile?.school?.id,
          selected_school_name: selectedProfile?.school?.name
        }
      })
    }

    setIsLoading(false)

    auth?.goToCloeWeb()
  }

  return (
    <>
      <header className={`${classes.header} ${!visible && 'hidden'}`}>
        <section className={classes.headerWrapper}>
          <div className={classes.headerContent}>
            <div className={classes.iconMenuContainer} onClick={() => setActiveMenuSidebar(!activeMenuSidebar)}>
              <SvgIcon.Menu className={classes.iconMenuSvg} />
            </div>
            <NavLink to={ROOT} onClick={redirectHomePage}>
              <Brand type='logo' size={document.body.clientWidth > theme.breakpoints.values.sm ? 'md' : 'sm'} />
            </NavLink>
          </div>
          <div className={classes.headerContent}>
            <Grid
              item
              className={classes.userInfoLogout}
              onClick={() => showVinculateModal()}
            >
              <SvgIcon.AddLink className={classes.iconSvg} data-testid='menu_my_profile_coodinator_link_profile_class_school' />
            </Grid>
            <VinculateModal />
            <div className={classes.schoolContainer}>
              <div className={classes.schoolNameContainer}>
                <div className={`${classes.unitLabel} ${openModal ? 'highlight' : ''}`}>
                  {t('Unidade')}
                </div>
                <div className={`${classes.schoolName} ${openModal ? 'highlight' : ''}`}>
                  {school?.name}
                </div>
              </div>
              <div className={`${classes.schoolChange} ${openModal ? 'highlight' : ''}`} onClick={handleOpenModal}>
                <div className={classes.iconChangeSchool}>
                  <SvgIcon.AutorenewRounded className={classes.iconSvg} />
                </div>
                <div className={`${classes.schoolChangeTitle} ${openModal ? 'highlight' : ''}`}>
                  <Text2 fontSize='xs' fontWeight='bold' lineHeight='xs' mobile='xxxs' colorbrand='medium' cursor="pointer" data-testid='menu_my_profile_coodinator_change_schools'>
                    {t('Trocar')}
                  </Text2>
                </div>
              </div>
            </div>
            <div className={classes.iconNotification}>
              {/* <SvgIcon.NotificationsOutlined className={classes.iconSvg} /> */}
            </div>
            <div className={classes.avatar} onClick={() => setActiveHeaderConfigs(!activeHeaderConfigs)}>
              <Avatar size='medium' fullname={session?.username ? session.username : 'CE'} />
            </div>
            <div className={`${classes.userInfo} ${activeHeaderConfigs && 'opened'}`}>
              <div className={classes.userInfoName}>
                {t('Olá Navegante!')}
              </div>
              <div
                className={classes.userInfoLogout}
                onClick={handleExit}
                data-testid='menu_my_profile_coodinator_leave_the_platform'
              >
                {t('Sair')}
              </div>
            </div>
          </div>
        </section>
      </header>
      <ChangeSchoolModal
        handleOpenConfirmationModal={handleOpenConfirmationModal}
        openModal={openModal}
        selectedProfile={selectedProfile}
        setOpenModal={setOpenModal}
        setSelectedProfile={setSelectedProfile}
      />
      <ConfirmationModal
        isLoading={isLoading}
        confirmButtonText={t('Confirmar troca')}
        cancelButtonText={t('Cancelar')}
        confirmCallback={handleSelectSchool}
        handleClose={handleCloseConfirmationModal}
        isOpen={openConfirmationModal}
        title={t('Ao confirmar a troca, iremos te redirecionar para a página inicial da escola escolhida')}
        cancelButtonDataTestid='change_profile_coordinator_back_my_classes'
        confirmButtonDataTestid='change_profile_coordinator_select'
      />
    </>
  )
}
