import { ProfileRegisterPageContainer, Tooltip } from 'components/common'
import { Grid, Link, Text2, Card, Button } from 'components/design-system'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useTranslation } from 'react-i18next'
import useStyles from './style'
import { useHistory } from 'react-router-dom'
import { PROFILE_TABS } from 'navigation/CONSTANTS'

export const CreateTeacherOptions: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const styles = useStyles()

  return (
    <ProfileRegisterPageContainer
      link={
        <Link data-testid='add_new_back_teachers_menu' variant='returnArrow' onClick={() => history.push(PROFILE_TABS('teachers'))} linkStyle>
          {t('Lista professores')}
        </Link>
      }
      title={
        <Grid container className={styles.heading}>
          <Text2 fontSize='xl' fontWeight='medium' lineHeight='sm' mobile='md' colorbrand='dark'>
            {t('Cadastrando novos professores')}
          </Text2>
        </Grid>
      }
      description={
        <Text2 fontSize='md' fontWeight='medium' lineHeight='xxl' mobile='sm' neutral='dark40'>
          {t('Selecione a forma de cadastro')}
        </Text2>
      }
    >
      <Grid container rowSpacing={2} className={styles.rightBoxContent}>
        <Grid item sm={12} md={5} className={styles.cardContainer}>
          <Card dataTestId='add_new_individual_registration' onClick={() => history.push(PROFILE_TABS('teachers/single-registration'))} clickable extraClasses={styles.card}>
            <Text2 fontSize='xl' fontWeight='medium' lineHeight='xxl' mobile='sm' mobilelineheight='lg' neutral='dark30' cursor="pointer">
              {t('Individual')}
            </Text2>
          </Card>
        </Grid>
        <Grid item sm={12} md={5} className={styles.cardContainer}>
          <Card dataTestId='add_new_mass_registration' onClick={() => history.push(PROFILE_TABS('teachers/bulk-registration'))} clickable extraClasses={styles.card}>
            <div className={styles.infoIconBox}>
              <Tooltip
                className={styles.tooltip}
                title='Cadastro de vários usuários usando uma planilha em Excel'
                arrow
              >
                <InfoOutlinedIcon sx={{ color: '#DADADA' }}/>
              </Tooltip>
            </div>
            <Text2 fontSize='xl' fontWeight='medium' lineHeight='xxl' mobile='sm' mobilelineheight='lg' neutral='dark30' cursor="pointer">
              {t('Em massa')}
            </Text2>
          </Card>
        </Grid>
        <Grid container item sm={12} justifyContent='flex-end' className={styles.cancelBtn}>
          <Button data-testid='add_new_cancel_registration' variant='outlined' onClick={() => history.push(PROFILE_TABS('teachers'))}>{t('Cancelar')}</Button>
        </Grid>
      </Grid>
    </ProfileRegisterPageContainer>
  )
}
