import { ChangeEvent, MouseEvent, createRef, KeyboardEvent } from 'react'
import { Button, Grid, Link, Pagination, Select, Tag, Text2 } from 'components/design-system'
import { SearchInput, SmallDropdownClassFilter, Tooltip } from 'components/common'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import makeStyles from './style'
import { useHistory } from 'react-router-dom'
import { useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'
import { IGrade, IGradeType, orderByNameOptions, statusClassOptions, SchoolPeriodType } from 'services'
import { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import { CLASS_MANAGEMENT } from 'navigation/CONSTANTS'
import { useTheme } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'
import { atomClassPropsData, atomGradeType, atomGrade, atomOrderBy, atomPage, atomSchoolPeriod, atomSearchTerm, atomActive } from 'pages/ClassManagement/atomStore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Theme, useMediaQuery } from '@mui/material'
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import { format } from 'date-fns'
import { useClassManagement } from 'navigation/hooks'
import { ActionsControlCenter, ActionContextEnum, SchoolClassManagerActionsEnum } from 'components/ActionsControlCenter'
import { useFetchAllGradeTypes } from 'pages/ClassManagement/hooks'

interface IClassHeader {
  handleClick: (event: MouseEvent<HTMLElement>) => void
  handleKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
  handleOpenEditSchoolPeriodModal: () => void
  handleOpenSchoolPeriodModal: () => void
  schoolPeriodType: SchoolPeriodType
}

export const ClassHeader: React.FC<IClassHeader> = ({ handleClick, handleKeyDown, handleOpenEditSchoolPeriodModal, handleOpenSchoolPeriodModal, schoolPeriodType }) => {
  const { t } = useTranslation()
  const customTheme = useTheme<CustomTheme>()
  const classes = makeStyles()
  const history = useHistory()
  const theme = useTheme<Theme>()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const reenrollmentButtonRef = createRef<HTMLDivElement>()
  const createClassButtonRef = createRef<HTMLDivElement>()
  const { schoolPeriodStatusType } = useClassManagement()
  const { allGradeTypeData } = useFetchAllGradeTypes()

  // atoms
  const [gradeType, setGradeType] = useAtom(atomGradeType)
  const [grade, setGrade] = useAtom(atomGrade)
  const [orderBy, setOrderBy] = useAtom(atomOrderBy)
  const [page, setPage] = useAtom(atomPage)
  const [searchTerm, setSearchTerm] = useAtom(atomSearchTerm)
  const [active, setActive] = useAtom(atomActive)
  const classPropsData = useAtomValue(atomClassPropsData)
  const schoolPeriod = useAtomValue(atomSchoolPeriod)

  const handleGradeType = (_gradeType: IGradeType | null) => {
    setGradeType(_gradeType)
    setGrade(null)
    setPage(1)
  }

  const handleGrade = (_grade: IGrade | null) => {
    if (gradeType) {
      setGrade(_grade)
      setPage(1)
    }
  }

  const handleChangePage = (_event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleOrderBy = (event: SelectChangeEvent) => {
    setOrderBy(event.target.value)
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(1)
    setSearchTerm(event.target.value)
  }

  const dateOffset = (date: string) => {
    const parsedDate = new Date(date)
    return parsedDate.valueOf() + parsedDate.getTimezoneOffset() * 60 * 1000
  }

  const formatDate = (dateToFormat: string) => format(dateOffset(dateToFormat), 'dd/MM/yy')

  const handleClickReenrollmentButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    reenrollmentButtonRef?.current?.click()
    history.push(CLASS_MANAGEMENT('config-class'))
  }

  const handleClickCreateClassButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    createClassButtonRef?.current?.click()
    history.push(CLASS_MANAGEMENT('create'))
  }

  return (
    <Grid container justifyContent='space-between' alignItems='center' className={classes.header} data-testid="class-filter-container">
      <Grid container item xs={12} md={12} rowSpacing={1} justifyContent='space-between'>
        <Grid container item xs={12} md='auto' alignItems='center' spacing={1}>
          <Grid item xs={isMobile ? 12 : 'auto'}>
            {
              schoolPeriod?.current
                ? <Text2 fontSize='xl' fontWeight={isMobile ? 'semibold' : 'medium'} lineHeight='xxs' mobile='lg' colorbrand='dark'>
                  {`${t('Ano escolar')}: ${schoolPeriod?.year ?? ''}`}
                </Text2>
                : <Text2 fontSize='xl' fontWeight={isMobile ? 'semibold' : 'medium'} lineHeight='xxs' mobile='lg' neutral='light40'>
                  {`${t('Ano escolar')}: ${schoolPeriod?.year ?? ''}`}
                </Text2>
            }
          </Grid>
          <Grid item>
            <Tag
              active
              background={schoolPeriodStatusType[schoolPeriodType].background}
              label={schoolPeriodStatusType[schoolPeriodType].label}
              textcolor={schoolPeriodStatusType[schoolPeriodType].color}
            />
          </Grid>
          <Grid item className={classes.changeSchoolPeriod}>
            <Text2 fontSize='xs' fontWeight='bold' lineHeight='md' mobile='xs' colorbrand='medium' cursor='pointer' onClick={handleOpenSchoolPeriodModal} data-testid='menu_class_management_change_year'>
              <AutorenewOutlinedIcon />{t('Trocar')}
            </Text2>
          </Grid>
        </Grid>
        {schoolPeriod?.allowEdit && (
          <Grid container item xs={12} md='auto' alignItems='center' spacing={1}>
            <Grid item xs={isMobile ? 12 : 'auto'}>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='sm' mobile='sm' neutral='dark40'>
                {t('Período letivo')}:
              </Text2>
            </Grid>
            <Grid item>
              <Tag
                active
                background={theme.colorBrand.medium}
                label={`${formatDate(schoolPeriod.start_date)} a ${formatDate(schoolPeriod.end_date)}`}
                textcolor={theme.colors.neutral.lightBase}
              />
            </Grid>
            {schoolPeriodType !== 'future' && (
              <Grid item>
                <Text2 fontSize='xs' fontWeight='semibold' lineHeight='sm' mobile='xxs' colorbrand='dark' cursor='pointer'>
                  <Link variant='link' nomargin onClick={handleOpenEditSchoolPeriodModal} data-testid='menu_class_management_edit_period'>
                    {t('Editar')}
                  </Link>
                </Text2>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} alignItems='center' columnSpacing={1} marginTop={2}>
        <ActionsControlCenter ctx={ActionContextEnum.schoolClassManager} action={SchoolClassManagerActionsEnum.re_enroll}>
          <Grid item xs={12} sm='auto' className={classes.importButtonGrid}>
            <div ref={reenrollmentButtonRef} data-testid='menu_class_management_import_class' hidden />
            <Button onClick={handleClickReenrollmentButton} startIcon={<AddOutlinedIcon />} variant='ghost'>
              {t('Rematrícula')}
            </Button>
          </Grid>
        </ActionsControlCenter>
        <ActionsControlCenter ctx={ActionContextEnum.schoolClassManager} action={SchoolClassManagerActionsEnum.create}>
          <Grid item xs={12} sm='auto'>
            <div ref={createClassButtonRef} data-testid='menu_students_list_classes_create_class' hidden />
            <Button onClick={handleClickCreateClassButton} startIcon={<AddOutlinedIcon />} variant='ghost'>
              {t('Criar turma')}
            </Button>
          </Grid>
        </ActionsControlCenter>
      </Grid>
      <Grid container item marginTop={2}>
        <Grid container item xs={12} alignItems='center' columnSpacing={isMobile ? 2 : 3}>
          <Grid item xs={12}>
            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='darkBase'>
              {t('Segmento')}
            </Text2>
          </Grid>
          <div className={classes.tagContainer}>
            <Grid container item xs={12} md='auto' wrap={isDesktop ? 'wrap' : 'nowrap'} columnSpacing={isMobile ? 2 : 3} rowSpacing={2}>
              <Grid item className={classes.tagBox}>
                <Tag
                  active={gradeType === null}
                  background={customTheme.colorBrand.dark}
                  label={t('Todos')}
                  onClick={() => handleGradeType(null)}
                  textcolor={customTheme.colors.neutral.lightBase}
                  data-testid='menu_students_list_classes_segment'
                />
              </Grid>
              {allGradeTypeData?.map((gradeTypeItem: IGradeType) =>
                <Grid key={gradeTypeItem.id} item className={classes.tagBox}>
                  <Tag
                    active={gradeTypeItem.id === gradeType?.id}
                    background={customTheme.colorBrand.dark}
                    label={gradeTypeItem.name}
                    onClick={() => handleGradeType(gradeTypeItem)}
                    textcolor={customTheme.colors.neutral.lightBase}
                    data-testid='menu_students_list_classes_segment'
                  />
                </Grid>
              )}
            </Grid>
          </div>
          <Grid container item xs={12} md={2} className={classes.counterTooltip}>
            <Grid item>
              <Text2 fontSize='xs' fontWeight='medium' lineHeight='sm' mobile='xxs' neutral='light40'>
                {
                  classPropsData?.totalStudents === 1
                    ? t('{{totalStudents}} estudante', { totalStudents: classPropsData?.totalStudents })
                    : t('{{totalStudents}} estudantes', { totalStudents: classPropsData?.totalStudents })
                }
              </Text2>
            </Grid>
            <Grid item>
              <Tooltip title='Estudantes cadastrados excluindo os desativados' arrow>
                <InfoOutlinedIcon fontSize={isMobile ? 'small' : 'medium'} sx={{ color: theme.colorBrand.medium, margin: '2px 8px', verticalAlign: 'middle' }} />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {gradeType && (
          <Grid container item xs={12} columnSpacing={isMobile ? 2 : 3} marginTop={3}>
            <Grid item xs={12}>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='darkBase'>
                {t('Ano')}
              </Text2>
            </Grid>
            <div className={classes.tagContainer}>
              <Grid container item xs={12} md='auto' wrap={isDesktop ? 'wrap' : 'nowrap'} columnSpacing={isMobile ? 2 : 3} rowSpacing={2}>
                <Grid item className={classes.tagBox}>
                  <Tag
                    active={grade === null}
                    background={customTheme.colorBrand.dark}
                    label={t('Todos')}
                    onClick={() => handleGrade(null)}
                    textcolor={customTheme.colors.neutral.lightBase}
                  />
                </Grid>
                {allGradeTypeData?.filter((gradeTypeItem: IGradeType) => gradeTypeItem.id === gradeType.id)
                  .map((gradeTypeItem: IGradeType) => (
                    gradeTypeItem?.grades?.length > 0
                      ? gradeTypeItem.grades?.map((gradeItem: IGrade) => (
                        <Grid item key={gradeItem.id}>
                          <Tag
                            active={gradeItem.id === grade?.id}
                            background={customTheme.colorBrand.dark}
                            label={gradeItem.name}
                            onClick={() => handleGrade(gradeItem)}
                            textcolor={customTheme.colors.neutral.lightBase}
                          />
                        </Grid>
                      ))
                      : <Text2 fontSize='sm' fontWeight='medium' lineHeight='lg' mobile='sm' neutral='dark30'>{t('Nenhuma turma encontrada')}</Text2>
                  ))}
              </Grid>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container columnSpacing={3} rowSpacing={1} marginTop={3} className={classes.labelContainer}>
        {!isMobile && (
          <Grid item xs={12} md>
            <SearchInput
              id='searchClass'
              className={classes.search}
              variant='filled'
              label={t('Pesquisar por nome')}
              onChange={handleSearch}
              onClick={handleClick}
              value={searchTerm}
              helperText={t('Pressione ENTER ou clique na lupa para pesquisar')}
              helperTextClassName={classes.helperText}
              onKeyDown={handleKeyDown}
            />
          </Grid>
        )}
        <Grid item xs={schoolPeriodType !== 'future' ? true : 12} md={4} lg={3}>
          <Select
            id='order-by'
            label={t('Ordenar por')}
            options={orderByNameOptions}
            value={orderBy}
            onChange={handleOrderBy}
            className={classes.orderBy}
            dataTestId='menu_students_list_classes_order_by'
          />
        </Grid>
        {schoolPeriodType !== 'future' && (
          <Grid item xs='auto'>
            <SmallDropdownClassFilter
              options={statusClassOptions}
              selected={active}
              setSelected={setActive}
            />
          </Grid>
        )}
        {
          !!classPropsData?.totalSize &&
              <Grid item xs={12} md={12} lg='auto' className={classes.pagination}>
                <Pagination
                  count={classPropsData?.lastPage}
                  page={page}
                  size='small'
                  onChange={handleChangePage}
                  dataTestIdElements={{
                    previous: 'menu_students_list_classes_back_previous_page',
                    page: 'menu_students_list_classes_select_page_number',
                    next: 'menu_students_list_classes_advance_next_page'
                  }}
                />
              </Grid>
        }
      </Grid>
    </Grid>
  )
}
