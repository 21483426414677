import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, useTheme } from '@mui/material'
import { Alert, Text2 } from 'components/design-system'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { NewEnrollmentsChart } from './components/NewEnrollmentsChart'

import { useStore } from 'store'

import { getEnrollmentData } from 'services/cloe-reports'
import { IEnrollmentDataDailyResponse, UserSchoolProfileTypeEnum } from 'services'

import useStyle from './style'
import moment from 'moment'
import { CloeTooltip, Pill } from 'components/common'

enum StateMachineEnum {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  READY = 'READY'
}

export const NewEnrollmentsSummary = () => {
  const { t } = useTranslation()

  const classes = useStyle()
  const theme = useTheme()

  const { school } = useStore()

  // states
  const [enrollmentData, setEnrollmentData] = useState<IEnrollmentDataDailyResponse>()
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(StateMachineEnum.IDLE)

  const schoolPeriodTitle = school?.school_periods?.find(el => el.current)?.title ?? moment().format('YYYY')

  useEffect(() => {
    fetchEnrollmentData()
  }, [school])

  const fetchEnrollmentData = async () => {
    setStateMachine(StateMachineEnum.LOADING)
    try {
      if (!school?.id) {
        throw new Error('Não foi possivel resgatar as informações da escola')
      }

      const response = await getEnrollmentData({ schoolId: school.id, profileType: UserSchoolProfileTypeEnum.student, frequency: 'day' })
      if (!response || !response.data) {
        throw new Error(response?.message ?? 'Erro ao resgatar informações de matriculas.')
      }

      setEnrollmentData(response.data as unknown as IEnrollmentDataDailyResponse)

      setStateMachine(StateMachineEnum.READY)
    } catch (err: any) {
      setStateMachine(StateMachineEnum.ERROR)
    }
  }

  const states = {
    IDLE: '',
    LOADING: <NewEnrollmentsChart.Skeleton />,
    ERROR: <Alert content={t('Falha na conexão de dados. Aguarde alguns instantes e tente recarregar a página')} actionDataTestId='data_connection_failure_access' outlined={true} severity='error' onActionClick={fetchEnrollmentData} action={`${t('Recarregar')}`} />,
    READY: <NewEnrollmentsChart.Item enrollmentData={enrollmentData} />
  }

  const renderMachineState = () => {
    return states[stateMachine]
  }
  return (
    <Grid container className={classes.LastWeekAccessContainer}>
      <Grid container>
        {renderMachineState()}
      </Grid>
      <Grid container item width='100%' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Text2
          fontSize='lg'
          fontWeight='semibold'
          lineHeight='xs'
          mobile='lg'
          customColor={theme.colorBrand.medium}
        >
          {t('Estudantes matriculados em turmas em {{year}}', { year: schoolPeriodTitle })}
          <CloeTooltip
            component={<InfoOutlinedIcon fontSize='medium' sx={{ marginLeft: theme.spacingInline.nano, color: theme.colorBrand.medium }} />}
            placement='top'
            text={t('Estudantes matriculados em turmas do ano vigente e com perfil ativo.')}
          />
        </Text2>
      </Grid>
      <Grid container item width='100%' style={{ display: 'flex', alignItems: 'center', gap: theme.spacingInline.nano }}>
        <Grid>
          <Pill label={String(enrollmentData?.nr_student_estimated ?? '-')} twoDigitsFormat={false} variant="success" size='small' />
          <Text2
            customColor={theme.colorBrand.medium}
            fontSize='xxs'
            fontWeight='medium'
            lineHeight='xs'
            mobile='xxs'
            sx={{ ml: 0.7 }}
          >
            {t('em contrato')}
          </Text2>
        </Grid>
        <Grid>
          <Text2
            customColor={theme.colorBrand.medium}
            fontSize='xxs'
            fontWeight='medium'
            lineHeight='xs'
            mobile='xxs'
            sx={{ ml: 0.7 }}
          >
            {t('{{number}} não matriculados', { number: enrollmentData?.notSubscribed ?? '-' })}
          </Text2>
        </Grid>
      </Grid>
    </Grid>
  )
}
