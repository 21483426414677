import { convertArrToGETParams } from 'utils/url'

interface IPROGRAM_CONTENT_RESOUCEParams {
  gradeType: number
  discipline?: number
  isAll?: boolean
}

export const PROGRAM_CONTENT_RESOURCE = ({ gradeType, discipline, isAll }: IPROGRAM_CONTENT_RESOUCEParams) => {
  const rawURL = `${process.env.REACT_APP_BACKEND_URL as string}/program-contents/grade-types/${gradeType}`
  const paramsObj = { discipline, grade_type: gradeType, all: isAll }

  return convertArrToGETParams({ rawURL, paramsObj })
}
