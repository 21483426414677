import React, { useEffect, useState } from 'react'
import makeStyles from './style'
import { useStore } from 'store'
import { Trans, useTranslation } from 'react-i18next'
import { Card, Container, Grid, Tag, Text2, Loading } from 'components/design-system'
import developerActivityImage from 'assets/developer-activity.svg'
import {
  getSchoolProgramByGradetypeAndGrade,
  IGrade,
  IGradeType,
  IProgramRequest
} from 'services'
import { useAtom } from 'jotai'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AddIcon from '@mui/icons-material/Add'
import { SCHOOL_PROGRAM_LIST, CLOE_PROGRAM_LIST } from 'navigation/CONSTANTS'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/styles'
import { CustomTheme } from 'styles/types/CustomTheme'
import {
  atomCurrentSelectedProgram,
  atomGradeType,
  atomGrade,
  atomDisciplineProgramList
} from '../../atomStore'
import { CreateSchoolProgramModal } from '../CreateSchoolProgramModal'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import { useFetchAllGradeTypes } from 'pages/ClassManagement/hooks'

export const ProgramManagement: React.FC = () => {
  const classes = makeStyles()
  const { school } = useStore()
  const { t } = useTranslation()
  const { createSnackbar } = useSnackbar()
  const { allGradeTypeData } = useFetchAllGradeTypes()

  const customTheme = useTheme<CustomTheme>()
  const history = useHistory()

  // atoms
  const [, setCurrentProgram] = useAtom(atomCurrentSelectedProgram)
  const [gradeType, setGradeType] = useAtom(atomGradeType)
  const [grade, setGrade] = useAtom(atomGrade)
  const [, setDisciplineList] = useAtom(atomDisciplineProgramList)

  // states
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [isCloeProgram, setIsCloeProgram] = useState<boolean | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getProgramByGradetypeAndGrade = async ({ gradeTypeId, gradeId, schoolId }: IProgramRequest) => {
    try {
      setIsLoading(true)
      const response = await getSchoolProgramByGradetypeAndGrade(gradeTypeId, gradeId, schoolId)
      if (response.success) {
        setCurrentProgram(response.data)
        setDisciplineList(response.data.disciplines)
        setIsCloeProgram(response.data.cloe_program)
      } else {
        createSnackbar({ content: 'Não foi possível carregar as expedições. Tente novamente', severity: 'error' })
      }
    } catch (err) {
      createSnackbar({ content: 'Não foi possível carregar as expedições. Tente novamente', severity: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (gradeType?.id && grade?.id && school?.id) {
      getProgramByGradetypeAndGrade({
        gradeTypeId: gradeType.id,
        gradeId: grade.id,
        schoolId: Number(school?.id)
      })
    }
  }, [gradeType, grade, school?.id])

  const handleGradeType = (_gradeType: IGradeType) => {
    setGradeType(_gradeType)
    setGrade(null)
  }

  const handleGrade = (_grade: IGrade) => {
    if (gradeType) {
      setGrade(_grade)
    }
  }

  const handleAccessProgram = () => {
    if (isCloeProgram) {
      history.push(CLOE_PROGRAM_LIST)
    } else {
      history.push(SCHOOL_PROGRAM_LIST)
    }
  }

  return (
    <Container>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid container item xs={12} md={6} spacing={3}>
          <Grid item xs={12} md={12}>
            <Text2 fontSize='xl' fontWeight='medium' lineHeight='sm' mobile='lg' colorbrand='darkest'>
              {t('Gestão de programa')}
            </Text2>
            <Grid item xs={12} md={12}>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='lg' neutral='light40'>
                <Trans>
                  Selecione o <strong>segmento e ano</strong> para visualizar os programas.
                </Trans>
              </Text2>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} rowSpacing={1} columnSpacing={2}>
            <Grid item xs={12} md={12}>
              <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='darkBase'>
                {t('Segmento')}
              </Text2>
            </Grid>
            {allGradeTypeData?.map((gradeTypeItem: IGradeType) =>
              <Grid item key={gradeTypeItem.id}>
                <Tag
                  active={gradeTypeItem.id === gradeType?.id}
                  background={customTheme.colorBrand.medium}
                  label={gradeTypeItem.name}
                  onClick={() => handleGradeType(gradeTypeItem)}
                  textcolor={customTheme.colors.neutral.lightBase}
                  data-testid='menu_management_program_segment_selection'
                />
              </Grid>
            )}
          </Grid>
          {gradeType &&
            <Grid container item xs={12} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='darkBase'>
                    {t('Ano')}
                  </Text2>
                </Grid>
                {allGradeTypeData?.filter((gradeTypeItem: IGradeType) => gradeTypeItem.id === gradeType.id)
                  .map((gradeTypeItem: IGradeType) => (
                    gradeTypeItem?.grades?.length > 0
                      ? gradeTypeItem.grades?.map((gradeItem: IGrade) => (
                        <Grid item key={gradeItem.id}>
                          <Tag
                            active={gradeItem.id === grade?.id}
                            background={customTheme.colorBrand.medium}
                            label={gradeItem.name}
                            onClick={() => handleGrade(gradeItem)}
                            textcolor={customTheme.colors.neutral.lightBase}
                            data-testid='menu_management_program_school_year'
                          />
                        </Grid>
                      ))
                      : <Text2 fontSize='sm' fontWeight='medium' lineHeight='lg' mobile='sm' neutral='dark30'>{t('Nenhuma turma encontrada')}</Text2>
                  ))}
              </Grid>
            </Grid>
          }
          {/* School program and Cloe program cards */}
          <Grid container item>
            {
              isLoading
                ? <Grid container sx={{ height: '553px' }}><Loading type='circular' /></Grid>
                : <Grid container rowSpacing={2} columnSpacing={5}>
                  {(gradeType && grade) &&
                    <>
                      <Grid item xs={12} md={6}>
                        <Card onClick={handleAccessProgram} extraClasses={classes.guide} clickable fullwidth={+true} dataTestId={isCloeProgram ? 'menu_management_program_access_cloe_program' : 'menu_management_program_access_school_program'}>
                          <Grid container rowSpacing={1}>
                            <Grid container item justifyContent='flex-start' alignItems='center'>
                              <Grid item>
                                <Text2 fontSize='md' fontWeight='semibold' lineHeight='xs' mobile='md' neutral='dark30' cursor="pointer">
                                  {isCloeProgram ? t('Programa cloe') : t('Programa da Escola')}
                                </Text2>
                              </Grid>
                            </Grid>
                            <Grid container item justifyContent='flex-end' alignItems='center'>
                              <Grid item>
                                <Text2 fontSize='xxs' fontWeight='semibold' lineHeight='xs' mobile='xs' colorbrand='medium' cursor="pointer">
                                  {t('Acessar')}
                                  <ArrowForwardIosIcon fontSize='small' />
                                </Text2>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                      {isCloeProgram &&
                        <Grid item xs={12} md={6}>
                          <Card onClick={() => setOpenModal(true)} clickable extraClasses={`${classes.guide} ${classes.centralized}`} dataTestId='menu_management_program_create_school_program'>
                            <Grid container justifyContent='center' alignItems='center'>
                              <Grid item>
                                <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='xs' colorbrand='medium' cursor="pointer">
                                  <AddIcon />
                                  {t('Criar programa')}
                                </Text2>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      }
                    </>
                  }
                  {/* Message Box */}
                  <Grid item xs={12} md={12}>
                    <Card extraClasses={classes.card} fullwidth={+true}>
                      {(!gradeType && !grade)
                        ? <>
                          <Grid item xs={12} md={12}>
                            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='xs' colorbrand='light'>
                              {`${t('Você ainda não selecionou um segmento e ano')} :(`}
                            </Text2>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='xs' colorbrand='light'>
                              <Trans>
                                Para acessar os programas disponíveis <strong>selecione o Segmento e Ano.</strong>
                              </Trans>
                            </Text2>
                          </Grid>
                        </>
                        : (gradeType && !grade)
                          ? <Grid item xs={12} md={12}>
                            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='xs' colorbrand='light'>
                              <Trans>
                                Agora <strong>selecione um Ano</strong> para acessar os programas disponiveis.
                              </Trans>
                            </Text2>
                          </Grid>
                          : <Grid item xs={12} md={12}>
                            <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='xs' colorbrand='light'>
                              <Trans>
                                Estes são os <strong>Programas disponíveis</strong> que temos para você.
                              </Trans>
                            </Text2>
                          </Grid>
                      }
                      <Grid item xs={12} md={12}>
                        <img src={developerActivityImage} />
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
      <CreateSchoolProgramModal
        open={openModal}
        programData={{
          gradeId: grade?.id ?? 0,
          schoolId: Number(school?.id),
          gradeTypeId: gradeType?.id ?? 0
        }}
        onOpen={setOpenModal} />
    </Container>
  )
}

export default ProgramManagement
