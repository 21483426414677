/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Card, Grid, Icon, Tag, Text2 } from 'components/design-system'
import { SheetStatusAccordion } from 'components/common'
import useStyles from './style'
import { Theme, Typography } from '@mui/material'
import { useTheme } from '@mui/styles'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { CustomTheme } from 'styles/types/CustomTheme'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import { getCfDownloadUrl } from 'services/fileDownload'
export interface ISheetStatusCardProps {
  children?: (boolean | React.ReactChild | React.ReactFragment | React.ReactPortal) & React.ReactNode
  fileName: string
  fileDate?: string
  status: string
  type: 'positive' | 'warning' | 'negative' | 'information'
  onCloseClick?: any
  showArrow?: boolean
  action?: React.ReactNode
  dataTestIdCancel?: string
  dataArrowTestid?: string
  transactionId?: string | null
}

export const SheetStatusCard: React.FC<ISheetStatusCardProps> = ({ action, children, fileName, fileDate, status, type, onCloseClick, showArrow, dataTestIdCancel, dataArrowTestid, transactionId, ...props }) => {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const customTheme = useTheme<CustomTheme>()

  const handleSheetDownload = async (event: any) => {
    event.stopPropagation()

    if (transactionId) {
      const result = await getCfDownloadUrl(transactionId)

      if (result.success) {
        window.location.href = `${result.data.url}` ?? '#'
      }
    }
  }

  if (showArrow) {
    return (
      <SheetStatusAccordion dataTestid={dataArrowTestid} title={fileName} subTitle={fileDate} status={{ label: status, type }} action={action} showDownload={!!transactionId} handleSheetDownload={handleSheetDownload}>
        {children ?? false}
      </SheetStatusAccordion>
    )
  }

  return (
    <Card extraClasses={styles.root} {...props}>
      <div className={styles.content}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          spacing={2}
        >
          <Grid item xs={12} md={3} xl={5}>
            <Typography noWrap><Text2 fontSize='md' fontWeight='medium' lineHeight='xxs' mobile='xs' neutral='dark30'>
              {fileName} <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xxs' mobile='xxxs' neutral='light20'>{fileDate}</Text2>
            </Text2></Typography>
          </Grid>
          <Grid container item justifyContent='flex-end' md={8} xl={6} spacing={2}>
            {status &&
              <Grid container item className={styles.statusContainer} xs={12} md={4} xl={4}>
                <Text2 fontSize='xxs' fontWeight='medium' lineHeight='xs' mobile='xxs' neutral='dark30' className={styles.statusLabel}>Status: </Text2>
                <Tag
                  active
                  background={theme.colors[type].feedbackLight}
                  label={status}
                  textcolor={customTheme.colors.neutral.darkBase}
                />
              </Grid>
            }
            {action &&
              <Grid container item className={styles.statusContainer} md={6} xl={6}>
                {action}
              </Grid>
            }
          </Grid>
          <Grid container item justifyContent='flex-end' md={1} xl={1}>
            {
              transactionId &&
              <DownloadOutlinedIcon sx={{ color: theme.colorBrand.dark, cursor: 'pointer' }} onClick={async (e) => await handleSheetDownload(e)} />
            }
          </Grid>
        </Grid>
        <div className={styles.iconWrapper}>
          {type === 'warning' &&
            <Icon titleAccess='CloseIcon' size='medium' data-testid={dataTestIdCancel} onClick={onCloseClick}>
              <CloseOutlinedIcon />
            </Icon>
          }
        </div>
      </div>
    </Card>
  )
}
