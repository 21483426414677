import { IGrade } from 'services'
import { numberPattern, regexPhone } from 'utils/regex'

export const formatCPF = (cpf: string) => {
  if (cpf) {
    // removes the unwanted characters...
    cpf = cpf.replace(/[^\d]/g, '')
    if (cpf.length === 11) return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    return cpf
  }
  return ''
}

export const formatPhone = (phone: string) => {
  if (phone) {
    if (phone.length === 9) {
      return phone
        .replace(/\D/g, '')
        .replace(/(\d)/, '$1')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+$/, '$1')
    }
    if (phone.length === 11) {
      return phone
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
        .replace(/(-\d{4})\d+$/, '$1')
    }
    return phone
  }
  return ''
}

export const isDoNotSendEmail = (email: string | undefined) => {
  const _email = email?.toLowerCase()
  return _email?.includes('donotsend') && _email?.includes('@camino.com.br')
}

export const isFormattedPhone = (phone: string) => !!(phone && phone !== '0' && phone.length && phone.match(regexPhone))

export const isUnformattedPhone = (phone: string) => !!(phone && phone !== '0' && phone.length && (phone.length === 9 || phone.length === 11) && phone.match(numberPattern))

export const replaceAutomaticEmail = (email: string | undefined, blankString?: boolean) => {
  if (isDoNotSendEmail(email) ?? !email) return blankString ? '' : '-'
  return email
}

export const replaceAutomaticPhone = (phone: string | undefined) => phone === '0' ? '' : formatPhone(phone ?? '')

export const returnEmailOrPhone = (email: string, phone: string) => {
  if (email.length && !isDoNotSendEmail(email)) {
    return {
      type: 'email',
      value: email
    }
  }

  if ((!email.length && !!phone) || (!!phone && isDoNotSendEmail(email))) {
    return {
      type: 'phone',
      value: formatPhone(phone)
    }
  }

  return {
    type: 'empty',
    value: '-'
  }
}

export const removeIlegalCharacteresFromFileName = (fileName: string) => fileName.replace(/[/\\?%*:|"<>.]/g, '')

export const sortGradesAsc = (grades: IGrade[] | undefined) => {
  if (grades) {
    const regExp = /\d+/
    return grades.sort((a, b) => (Number(a?.name.match(regExp))) - (Number(b?.name.match(regExp))))
  }
  return []
}

export const truncateString = (text: string, take: number) => text.length && take > 0
  ? `${text.substring(0, take).trim()}...`
  : ''
