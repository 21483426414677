import { IMediaResponse } from './program-content'

export enum ActivityControlStatusEnum {
  AVAILABLE = 0,
  PENDING = 1,
  DONE = 2,
  CLOSED = 3,
  CORRECTED = 4,
  RELEASED = 5
}

export interface IRecordStatusControlRequest {
  activityId: number
  activityStatus: ActivityControlStatusEnum | null
}

export const useTypeDicitonary: { [index: string]: string } = {
  expedition_quiz: 'Quiz',
  final: 'Final',
  diagnostic: 'Diagnóstica',
  observation: 'Observação',
  achievement: 'Conquista',
  procedural: 'Procedural',
  self_assessment: 'Autoavaliação',
  article_quiz: 'Quiz',
  article_check: 'Quiz',
  quiz_v1: 'Quiz',
  drawing: 'Atividade',
  puzzle: 'Atividade',
  numbers: 'Atividade',
  writing: 'Atividade',
  words: 'Atividade',
  challenge: 'Atividade'
}

export const ActivityTypeDescription = {
  quiz: 'Quiz',
  record: 'Registro',
  draw: 'Desenho',
  audio: 'Áudio',
  fill_in: 'Preencher',
  essay: 'Redação',
  read: 'Leitura',
  reflect: 'Reflexão',
  discuss_duo: 'Discussão em Dupla',
  discuss: 'Discussão',
  listen: 'Ouvir',
  watch: 'Assistir',
  evaluation: 'Avaliação',
  expedition_quiz: 'expedition_quiz',
  final: 'final',
  diagnostic: 'diagnostic',
  observation: 'observation',
  achievement: 'achievement',
  procedural: 'procedural',
  self_assessment: 'self_assessment',
  article_quiz: 'article_quiz',
  article_check: 'article_check',
  quiz_v1: 'quiz_v1',
  drawing: 'drawing',
  puzzle: 'puzzle',
  numbers: 'numbers',
  writing: 'Writing',
  words: 'words',
  challenge: 'challenge',
  guide: 'guide',
  speaking: 'Speaking',
  reading: 'Reading',
  listening: 'Listening',
  game: 'Game'
}

export enum ActivityUseTypeEnum {
  EXPEDITION_QUIZ = 'expedition_quiz',
  FINAL = 'final',
  DIAGNOSTIC = 'diagnostic',
  OBSERVATION = 'observation',
  ACHIEVEMENT = 'achievement',
  PROCEDURAL = 'procedural',
  SELF_ASSESSMENT = 'self_assessment',
  ARTICLE_QUIZ = 'article_quiz',
  ARTICLE_CHECK = 'article_check',
  QUIZ_V1 = 'quiz_v1',
  DRAWING = 'drawing',
  PUZZLE = 'puzzle',
  NUMBERS = 'numbers',
  WRITING = 'writing',
  WORDS = 'words',
  CHALLENGE = 'challenge'
}

export const ActivityUseTypeDescripion = {
  expedition_quiz: 'Quiz',
  final: 'Final',
  diagnostic: 'Diagnostica',
  observation: 'Observação',
  achievement: 'Conquista',
  procedural: 'Procedural',
  self_assessment: 'Auto avaliação',
  article_quiz: 'Quiz',
  article_check: 'Quiz',
  drawing: 'Desenho',
  puzzle: 'Rachando a cuca',
  words: 'Detetive das palavras',
  challenge: 'Desafios',
  writing: 'Escrever',
  numbers: 'Lendo e escrevendo números'
}

export interface IResourceResponse {
  id: number
  title: string
  code: string
  source: string | null
  type: string
  is_embeded: boolean
  show_on_library: string | null
  legacy_id: number
  description: string | null
  html_content: string | null
  link: string
  locale: string
  published_at: string
  created_at: string
  updated_at: string
  medias?: IMediaResponse[]
}

export type IResourceTypesDataTestId = 'site' | 'text' | 'video' | 'image' | 'game' | 'audio' | 'app' | 'infographic' | 'interactive' | 'tool' | 'gallery'

export const resourceTypesDataTestId = {
  site: 'resource_site',
  text: 'resource_text',
  video: 'resource_video',
  image: 'resource_image',
  game: 'resource_game',
  audio: 'resource_audio',
  app: 'resource_app',
  infographic: 'resource_infographic',
  interactive: 'resource_interactive',
  tool: 'resource_tool',
  gallery: 'resource_gallery',
  image_embed: 'resource_image_embed',
  video_embed: 'resource_video_embed'
}

export enum SuggestedApplicationTypeLabelEnum {
  activity = 'Atividade',
  evaluation = 'Avaliação'
}

export interface ISchoolsClassResponse {
  schools: Array<{
    id: number
    name: string
    userSchoolProfile: number
    classes: Array<{
      grades: Array<{ id: number, name: string, code: string }>
      id: number
      subscription_id: number
      name: string
    }>
  }>
}
