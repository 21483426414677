import { ROOT } from './CONSTANTS'
import { Text2, Grid, Button, Container } from 'components/design-system'
import { useHistory } from 'react-router-dom'
import yellowCat from 'assets/yellow-cat.svg'

export const NotFound = () => {
  const history = useHistory()

  return (
    <Container>
      <Grid container justifyContent="center" alignItems='center' spacing={2}>
        <Grid container item justifyContent="center" alignItems='center'>
          <Text2 fontSize='xxxl' fontWeight='semibold' lineHeight='xl' mobile='xxl' neutral='dark30'>Ops!</Text2>
        </Grid>
        <Grid container item justifyContent="center" alignItems='center'>
          <img src={yellowCat} alt="Yellow cat playing with a wool ball" />
        </Grid>
        <Grid container item justifyContent="center" alignItems='center'>
          <Text2 fontSize='sm' fontWeight='medium' lineHeight='xl' mobile='xxl' neutral='dark30'>Não encontramos esta página</Text2>
        </Grid>
        <Grid container item justifyContent="center" alignItems='center'>
          <Button variant="primary" onClick={() => history.push(ROOT)}>
            Ir para Home
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
