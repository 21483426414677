import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export default makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      marginTop: theme.spacingStack.lg,
      borderRadius: theme.borderRadius.sm,
      '& .MuiDialog-paper::-webkit-scrollbar': {
        width: '30px'
      },
      '& .MuiDialog-paper::-webkit-scrollbar-thumb': {
        background: '#C4C4C4',
        borderRadius: '40px',
        backgroundClip: 'content-box',
        border: 'solid 10px transparent'
      }
    },
    modalBody: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacingStack.xxxs,
      backgroundColor: theme.colors.neutral.lightBase,
      padding: `0 ${theme.spacingInset.xs} ${theme.spacingInset.xs}`
    },
    modalHeader: {
      display: 'flex',
      padding: theme.spacingInset.sm
    },
    closeContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer'
    },
    headerText: {
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.medium
    },
    modalFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: theme.spacingInset.xs,
      backgroundColor: 'rgba(230, 230, 230, 0.32)'
    }
  })
)
