import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Grid, Modal, Tag, Text2 } from 'components/design-system'
import useStyle from './style'
import { ISchoolPeriod } from 'services'
import { useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { atomSchoolPeriods, atomSchoolPeriod, atomPreSelectedSchoolPeriod, atomReloadClasslist } from 'pages/ClassManagement/atomStore'
import { useClassManagement } from 'navigation/hooks'
import { useAppcues } from 'components/AppCues/useAppcues'
import { AppcuesEventsEnum } from 'components/AppCues/events'
interface ISchoolPeriodModal {
  handleClose: () => void
  open: boolean
}

export const SchoolPeriodModal: React.FC<ISchoolPeriodModal> = ({ handleClose, open }) => {
  const { t } = useTranslation()
  const { getSchoolPeriodType, resetClassFilters, schoolPeriodStatusType } = useClassManagement()
  const classes = useStyle()
  const { eventDispatcher } = useAppcues()

  // atoms
  const [preSelectedSchoolPeriod, setPreSelectedSchoolPeriod] = useAtom(atomPreSelectedSchoolPeriod)
  const [schoolPeriod, setSchoolPeriod] = useAtom(atomSchoolPeriod)
  const schoolPeriods = useAtomValue(atomSchoolPeriods)
  const setReloadClassList = useUpdateAtom(atomReloadClasslist)

  const handleSelectSchoolPeriod = () => {
    if (preSelectedSchoolPeriod && schoolPeriods) {
      const foundSchoolPeriod: ISchoolPeriod | undefined = schoolPeriods?.find(sp => sp.id === preSelectedSchoolPeriod.id)
      if (foundSchoolPeriod) {
        setSchoolPeriod(foundSchoolPeriod)
        resetClassFilters(getSchoolPeriodType(preSelectedSchoolPeriod))
        setReloadClassList(true)
      }
    }
    handleClose()
  }

  const handleCloseWithReset = () => {
    setPreSelectedSchoolPeriod(schoolPeriod)
    handleClose()
  }

  useEffect(() => {
    if (open) eventDispatcher(AppcuesEventsEnum.adm_modal_adicionar_ano)
  }, [open])

  return (
    <Modal open={open} onClose={handleCloseWithReset} size='md' className={classes.modal}>
      <Grid container justifyContent='center' rowSpacing={2}>
        <Grid item>
          <Text2 fontSize='md' fontWeight='semibold' lineHeight='sm' mobile='md' colorbrand='darkest'>
            {t('Ano escolar')}
          </Text2>
        </Grid>
        {(
          <>
            <Grid container item justifyContent='center'>
              {schoolPeriods?.length
                ? schoolPeriods?.map((sp: ISchoolPeriod) => (
                  <Grid item xs={12} key={sp.id}>
                    <Card
                      onClick={() => setPreSelectedSchoolPeriod(sp)}
                      extraClasses={classes.schoolYearCard}
                      selected={sp.id === preSelectedSchoolPeriod?.id}
                      clickable
                      dataTestId='menu_class_management_change_year_select_year'
                    >
                      <Grid container justifyContent='space-between'>
                        <Grid item>{sp.year}</Grid>
                        <Grid item>
                          <Tag
                            active
                            background={schoolPeriodStatusType[getSchoolPeriodType(sp)].background}
                            label={schoolPeriodStatusType[getSchoolPeriodType(sp)].label}
                            textcolor={schoolPeriodStatusType[getSchoolPeriodType(sp)].color}
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                ))
                : (
                  <Grid item>
                    <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' neutral='dark30' mobile='md'>
                      {t('Nenhum resultado encontrado')}
                    </Text2>
                  </Grid>
                )
              }
            </Grid>
            <Grid container item justifyContent='space-between'>
              <Grid item>
                <Button variant='ghost' onClick={handleCloseWithReset} data-testid='menu_class_management_change_year_cancel'>
                  {t('Cancelar')}
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={handleSelectSchoolPeriod} disabled={schoolPeriod === preSelectedSchoolPeriod} data-testid='menu_class_management_change_year_confirm'>
                  {t('Selecionar')}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Modal>
  )
}
