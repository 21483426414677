import { Snackbar, Loading, Grid, Container } from 'components/design-system'
import { Header, Sidebar } from 'navigation/components'
import { useEffect, useState } from 'react'
import BaseScreenContext from './components/BaseScreenContext'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import useStyle from './style'
import Storage from 'utils/storage'
import { useAtom } from 'jotai'
import { atomSnackbar } from './atomStore'
import { useSnackbar } from 'navigation/hooks'
import { checkBlockedUser } from 'helpers/CheckBlockedUser'
import { useAuth } from 'navigation/ProvideAuth'

export const BaseScreen: React.FC = ({ children }) => {
  const classes = useStyle()
  const { createSnackbar } = useSnackbar()
  const auth = useAuth()
  const hasLoggedUserBeenBlocked = checkBlockedUser.getUserBlockedStatus()

  // states
  const [activeMenu, setActiveMenu] = useState<boolean>(false)
  const [activeMenuClass, setActiveMenuClass] = useState<boolean>(false)
  const [showScroll, setShowScroll] = useState(false)
  const [open, setOpen] = useState(false)

  // atoms
  const [snackbar] = useAtom(atomSnackbar)

  useEffect(() => {
    document.addEventListener('scroll', checkScrollTop)
  }, [])

  useEffect(() => {
    if (hasLoggedUserBeenBlocked) {
      createSnackbar({ content: 'Seu cadastro está bloqueado. Entre em contato com a gestão da escola.', severity: 'error' })
      Storage.token.remove()
      Storage.props.remove()

      auth?.signout()
    }
  }, [hasLoggedUserBeenBlocked])

  useEffect(() => {
    if (snackbar) setOpen(true)
  }, [snackbar])

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  // scroll
  const checkScrollTop = () => {
    if (!showScroll && window.scrollY > 300) {
      setShowScroll(true)
    } else if (showScroll && window.scrollY <= 300) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className={classes.baseScreenContainer}>
      <BaseScreenContext.Provider value={{ activeMenu, setActiveMenu, activeMenuClass, setActiveMenuClass }}>
        <Header />
        <Sidebar />
        <div className={classes.baseScreenWrapper}>
          <div className={classes.wrapper}>
            {hasLoggedUserBeenBlocked
              ? <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh!important' }}>
                <Grid container justifyContent="center" alignItems='center'>
                  <Loading type="circular" />
                </Grid>
              </Container>
              : children
            }
          </div>
        </div>
        <Snackbar
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
          severity={snackbar?.severity}
          vertical={snackbar?.vertical ?? 'bottom'}
          horizontal={snackbar?.horizontal ?? 'left'}
          content={snackbar?.content}
        />
      </BaseScreenContext.Provider>
      {showScroll &&
        <button type='button' className={classes.scrollTopBtn} onClick={() => scrollTop()}>
          <ArrowUpwardIcon />
        </button>
      }
    </div>
  )
}

export default BaseScreen
