import { generateRegistrationToken, IRegistrationTokenRequest, UserSchoolProfileTypeEnum } from 'services'
import { add } from 'date-fns'
import { useSnackbar } from 'navigation/hooks/useSnackbar'
import { useStore } from 'store'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import { useToken } from 'pages/ProfileTabs/hooks'
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined'
import { IconButton, useTheme } from '@mui/material'
import {
  Grid,
  Loading,
  Text2
} from 'components/design-system'
import { useTranslation } from 'react-i18next'
import makeStyles from './style'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { ClipBoardCopy } from 'components/common'
import { formatSecondsToTime } from 'utils'

export interface IAutonomousRegistrationCodeButtonProps {
  dataTestId?: string
}

const MINUTE = 60
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HOUR = 60 * MINUTE

export const AutonomousRegistrationCodeButton: React.FC<IAutonomousRegistrationCodeButtonProps> = ({ dataTestId }) => {
  const { createSnackbar } = useSnackbar()
  const { school } = useStore()
  const { getToken, token } = useToken()
  const classes = makeStyles()
  const theme = useTheme()

  const [countDown, setCountDown] = useState(0) // seconds
  const [isLoading, setIsLoading] = useState(false)

  const shouldShowCounter = token?.expire_at && countDown <= 30 * MINUTE && countDown > 0
  const tokenCode = token?.token ?? '000000'
  const countDownText = formatSecondsToTime(countDown)

  useEffect(() => {
    if (!token?.expire_at) return

    const diffInMinutes = moment(token.expire_at).diff(moment(), 'seconds')

    setCountDown(diffInMinutes)
  }, [token])

  useEffect(() => {
    if (countDown <= 0) return

    const checkTime = shouldShowCounter
      ? 1 * 1000 // 1 SEC
      : MINUTE * 1000 // 1 MIN

    const timer = setInterval(() => setCountDown(countDown - 1), checkTime)

    return () => clearInterval(timer)
  }, [countDown])

  const { t } = useTranslation()

  const handleGenerateToken = async () => {
    try {
      setIsLoading(true)

      const json: IRegistrationTokenRequest = {
        type: UserSchoolProfileTypeEnum.teacher,
        class: 0,
        school: school?.id ?? 0,
        users_permissions_role: 1,
        expire_at: add(new Date(), { hours: 12 }).toISOString(),
        active: true
      }

      const response = await generateRegistrationToken(json)

      if (response.success) {
        getToken()
      } else {
        throw new Error('Erro ao tentar gerar token')
      }
    } catch (e) {
      if (e instanceof Error) {
        createSnackbar({ content: e.message, severity: 'error' })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const CodeComponent = () => {
    if (tokenCode && countDown > 0) {
      return (
        <Grid item>
          <Grid container alignItems='center' gap={theme.spacingInline.nano}>
            <Grid item sx={{ width: 'auto' }}>
              <Text2 className={classes.code} fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xs' colorbrand='darkest'>
                {tokenCode}
              </Text2>
            </Grid>
            <Grid item>
              <ClipBoardCopy className={classes.customButton} dataTestId='manage_teachers_copy_code_autonomous_registration' copyItem={tokenCode} />
            </Grid>
            <Grid item>
              <IconButton className={classes.customButton} onClick={handleGenerateToken}>
                <AutorenewOutlinedIcon data-testid={'manage_teachers_generate_autonomous_registration_code'} fontSize='small' sx={{ color: theme.colorBrand.medium }} />
              </IconButton>
            </Grid>
            {shouldShowCounter && (
              <Grid item className={classes.countdownText}>
                <Text2 fontSize='sm' fontWeight='semibold' lineHeight='xs' mobile='xs' colorbrand='light'>
                  {countDownText}
                </Text2>
              </Grid>
            )}
          </Grid>
        </Grid>
      )
    }

    return (
      <Text2 cursor='pointer' fontSize='xs' fontWeight='bold' lineHeight='xs' mobile='xs' colorbrand='medium' onClick={handleGenerateToken} data-testid='manage_teachers_generate_autonomous_registration_code'>
        {t('Gerar código')}
        <LockOpenOutlinedIcon />
      </Text2>
    )
  }

  return (
    <Grid container item xs={12} md='auto' alignItems='center' justifyContent='center' columnSpacing={1}>
      <Grid item xs={12} md='auto' className={classes.autonomousCodeContainer}>
        <Grid className={classes.alignMiddle}>
          <Text2 fontSize='xs' fontWeight='medium' lineHeight='xs' mobile='xs' neutral='dark20' noWrap>
            {t('Cadastro autônomo:')}
          </Text2>
        </Grid>

        {
          isLoading ? <Loading size={24} sx={{}} type="circular" /> : <CodeComponent />
        }
      </Grid>
    </Grid>
  )
}
