import useStyles from './style'
import { ResourceCaption } from 'components/common'

interface AudioPlayerProps {
  url: string
  source?: string | null
  description?: string | null
}

const convertAudioUrl = (url: string, source: string | null | undefined, description: string | null | undefined) => {
  const classes = useStyles()

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.audio}>
        <audio controls src={`${url}`}></audio>
      </div>
      <ResourceCaption source={source} description={description}></ResourceCaption>
    </div>
  )
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ url, source, description }) => {
  return (
    convertAudioUrl(url, source, description)
  )
}
