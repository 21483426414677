import { useTranslation } from 'react-i18next'
import { Button, Grid, Text2 } from 'components/design-system'
import useStyle from './style'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { SchoolPeriodType } from 'services'

export interface ISchoolPeriodAccordion {
  handleExpanded: () => void
  isExpanded: boolean
  schoolPeriodType: SchoolPeriodType
}

export const SchoolPeriodAccordion: React.FC<ISchoolPeriodAccordion> = ({ handleExpanded, isExpanded, schoolPeriodType }) => {
  const { t } = useTranslation()
  const classes = useStyle({ handleExpanded, isExpanded, schoolPeriodType })

  return (
    <>
      {
        schoolPeriodType !== 'current' && (
          <Accordion className={classes.previousSchoolYear} expanded={isExpanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon fontSize='large' className={classes.expandIcon} onClick={handleExpanded} />}>
              <Grid container justifyContent='center' alignItems='center' spacing={1} onClick={handleExpanded}>
                <Grid item>
                  <EventRepeatOutlinedIcon fontSize='large' className={classes.calendarIcon} />
                </Grid>
                <Grid item>
                  <Text2 fontSize='sm' fontWeight='bold' lineHeight='xs' mobile='xs' cursor="pointer" neutral='lightBase'>
                    {t(`Ano escolar ${schoolPeriodType === 'future' ? 'futuro' : 'passado'}`)}
                  </Text2>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction='column' justifyContent='center' spacing={2}>
                <Grid item xs={10} md={7}>
                  <Text2 fontSize='sm' fontWeight='medium' lineHeight='xs' mobile='sm' cursor="pointer" neutral='lightBase'>
                    {schoolPeriodType === 'future'
                      ? t('Este ano está inativo. Será ativado automaticamente na data definida. Você poderá editar as turmas, mas só estarão disponíveis quando este se tornar o ano vigente.')
                      : t('Este é um ano anterior. Você pode somente visualizar as turmas antigas. Apenas anos escolares atuais permitem edição.')
                    }
                  </Text2>
                </Grid>
                <Grid item>
                  <Button variant='outlined' className={classes.understandButton} onClick={handleExpanded}>
                    {t('Entendi!')}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )
      }
    </>
  )
}
