import {
  IApiResponse,
  IClassRequest,
  IClassResponse,
  IClassListObject,
  IDisciplineResponse,
  request,
  IClassGetStudentsGroupingByClassRequest,
  IClassItem
} from 'services'
import {
  CLASS_CREATE,
  CLASS_GET_ONE,
  CLASS_PUT,
  CLASS_DISCIPLINE,
  CLASS_DELETE,
  CLASS_GET_BY_SCHOOL,
  CLASS_GET_BY_NAME_AND_SCHOOL_AND_SCHOOL_PERIOD,
  CLASS_GET_STUDENTS_GROUPING_BY_CLASS
} from './CONSTANTS'

export const createClass = async (data: IClassRequest): Promise<IApiResponse<IClassResponse>> =>
  await request({ url: CLASS_CREATE(), method: 'post', data: data })

export const getClasses = async (schoolId: number, schoolPeriod: number, page: number, active: number, gradeTypeId?: number, gradeId?: number, sort?: string, search?: string): Promise<IApiResponse<IClassListObject>> =>
  await request({ url: CLASS_GET_BY_SCHOOL(schoolId, schoolPeriod, page - 1, 30, sort ?? 'asc', active, gradeTypeId, gradeId, search) })

export const getOneClass = async (classId: number): Promise<IApiResponse<IClassResponse>> =>
  await request({ url: CLASS_GET_ONE(classId) })

export const editOneClass = async (classId: number, data: IClassRequest): Promise<IApiResponse<IClassResponse>> =>
  await request({ url: CLASS_PUT(classId), method: 'put', data: data })

export const getClassDisciplines = async(classId: number): Promise<IApiResponse<IDisciplineResponse[]>> =>
  await request({ url: CLASS_DISCIPLINE(classId) })

export const deleteClass = async(classId: number, gradeId?: number): Promise<IApiResponse<IClassResponse>> => await request({ url: CLASS_DELETE(classId, gradeId), method: 'delete' })

export const getClassByNameAndSchoolAndSchoolPeriod = async (className: string, schoolId: number, schoolPeriodId: number): Promise<IApiResponse<IClassResponse[]>> =>
  await request({ url: CLASS_GET_BY_NAME_AND_SCHOOL_AND_SCHOOL_PERIOD(className, schoolId, schoolPeriodId) })

export const getStudentsGroupingByClass = async (data: IClassGetStudentsGroupingByClassRequest): Promise<IApiResponse<IClassItem[]>> =>
  await request({ url: CLASS_GET_STUDENTS_GROUPING_BY_CLASS(), method: 'post', data: data })
