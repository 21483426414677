import { IResourceResponse, IResourceTypesDataTestId, resourceTypesDataTestId } from 'services/types'
import useStyles from './style'
import { resourceTypeResolver } from 'components/common/Resource'
import { Icon } from 'components/design-system/Icon'
import OpenInNew from '@mui/icons-material/OpenInNew'
import { Link } from 'components/design-system'

interface ILinks {
  link: string
  title: string
}

export const NonEmbeddedResource: React.FC<IResourceResponse> = resource => {
  const classes = useStyles()

  // verifica se tem o medias para pegar o link correto do botão
  const links: ILinks[] = []
  if (resource.medias?.length) {
    // se tiver só um media, adota o titulo do recurso, se tiver mais de um, adota o nome dado a cada media
    resource.medias.forEach(cur => links.push({ link: cur.url, title: resource.medias?.length === 1 ? resource.title : cur.name }))
  } else {
    // medias tem prioridade sobre o link direto, eles usavam como fallback no cadastro o link (Não tem o cenário que tem que aparecer o que tem em medias E link)
    if (resource.link) links.push({ link: resource.link, title: resource.title })
  }

  const handleResourceDataTestId = () => {
    if (resource.is_embeded && resource.type === 'image') {
      return resourceTypesDataTestId.image_embed
    } else if (resource.is_embeded && resource.type === 'video') {
      return resourceTypesDataTestId.video_embed
    }

    return resourceTypesDataTestId[resource.type as IResourceTypesDataTestId]
  }

  return (
    <div className={classes.nonEmbeddedResource}>
      <div className={classes.type}>
        <Icon size='medium'>
          <OpenInNew />
        </Icon>
        <span>{resourceTypeResolver(resource.type)}</span>
      </div>
      {
        links.map(cur => {
          return (
            <div key={cur.title} className={classes.title}>
              <Link
                variant='primary'
                href={cur.link}
                target='_blank'
                data-testid={handleResourceDataTestId()}
              >
                {cur.title}
              </Link>
            </div>
          )
        })
      }
      <div
        className={classes.description}
        dangerouslySetInnerHTML={{
          __html: resource.description ?? ''
        }}
      />
      <div className={classes.source}>
        {resource.source}
      </div>
    </div>
  )
}
