export const AUTH_PAGEX = '/authorizedx'
export const LOGIN = '/login'
export const PAGEX = '/pagex'
export const ROOT = '/'
export const TERMS_ACCEPTANCE = '/termsAcceptance'

export const PROFILE_TABS = (tab?: string) => `/profile-tabs/${tab ?? ':tab'}`

export const CLASS_MANAGEMENT = (action?: string) => `/class/management/${action ?? ':action'}`

export const USER_MANAGEMENT = (action?: string) => `/user/management/${action ?? ':action'}`

// program routes
export const CONTENT_MANAGEMENT = (tab?: string) => `/program/${tab ?? ':tab'}`
export const PROGRAM_MANAGEMENT = CONTENT_MANAGEMENT('program-management')
export const SCHOOL_PROGRAM_LIST = CONTENT_MANAGEMENT('program-management/school-list')
export const CLOE_PROGRAM_LIST = CONTENT_MANAGEMENT('program-management/cloe-list')

// report
export const REPORT = '/report'
export const REPORT_VIEW = '/report/view'
export const CLOE_WEB = process.env.REACT_APP_CLOE_PROJECT as string

// feature toggle
export const ENABLE_FEATURE_TOGGLE = (featureToggle?: string) => `/enable-feature-toggle/${featureToggle ?? ':featureToggle'}`
