import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { IProgressBar } from '.'

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '& *': {
      cursor: 'default'
    },
    '& .MuiSlider-mark': {
      backgroundColor: 'transparent'
    },
    '& .MuiSlider-markLabel': {
      color: theme.colors.neutral.darkBase,
      fontSize: theme.font.fontSize.xxs,
      fontWeight: theme.font.fontWeight.medium,
      lineHeight: theme.font.lineHeight.md,
      top: '30px'
    },
    '& .MuiSlider-rail': {
      backgroundColor: 'rgba(230, 230, 230, 0.64)',
      borderRadius: '500px',
      height: '32px',
      opacity: '1'
    },
    '&.MuiSlider-root': {
      padding: '13px 0'
    },
    '& .MuiSlider-thumb': {
      height: '0',
      width: '0',
      '&.Mui-active, &:hover': {
        boxShadow: 'none'
      }
    },
    '& .MuiSlider-track': {
      backgroundColor: (props: IProgressBar) => props.feedbacktheme?.feedbackLight ?? theme.colors.neutral.darkBase,
      border: '0',
      borderRadius: (props: IProgressBar) => (props.value !== undefined && props.value < 1) ? '500px 0 0 500px' : '500px',
      height: '32px'
    },
    '& .MuiSlider-valueLabel': {
      backgroundColor: 'transparent',
      color: (props: IProgressBar) => props.feedbacktheme?.feedbackDarkest ?? theme.colors.neutral.darkBase,
      fontSize: theme.font.fontSize.xs,
      fontWeight: theme.font.fontWeight.semibold,
      right: (props: IProgressBar) => (props.value !== undefined && props.value < 1) ? '-40px' : '0',
      top: '14px'
    }
  }
}))
