import { Theme } from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { IFontProps } from './index'

const defineColor = (props: IFontProps, theme: Theme) => {
  if (props.neutral) {
    return theme.colors.neutral[props.neutral]
  }
  if (props.positive) {
    return theme.colors.positive[props.positive]
  }
  if (props.negative) {
    return theme.colors.negative[props.negative]
  }
  if (props.warning) {
    return theme.colors.warning[props.warning]
  }
  if (props.information) {
    return theme.colors.information[props.information]
  }
  if (props.colorbrand) {
    return theme.colorBrand[props.colorbrand]
  }

  if (props.customColor) {
    return props.customColor
  }

  return '#DDD'
}

export default makeStyles((theme: Theme) => createStyles({
  root: {
    '&.MuiTypography-root': {
      fontFamily: theme.font.fontFamily,
      letterSpacing: theme.font.spacing.distant,
      fontSize: (props: IFontProps) => theme.font.fontSize[props.fontSize],
      fontWeight: (props: IFontProps) => theme.font.fontWeight[props.fontWeight],
      lineHeight: (props: IFontProps) => theme.font.lineHeight[props.lineHeight],
      color: (props: IFontProps) => defineColor(props, theme),
      cursor: (props: IFontProps) => props.cursor ?? 'default',
      paddingLeft: (props: IFontProps) => props.paddingLeft ? theme.spacingStack[props.paddingLeft] : '0',
      [theme.breakpoints.down('lg')]: {
        fontSize: (props: IFontProps) => theme.font.fontSize[props.mobile],
        lineHeight: (props: IFontProps) => props.mobilelineheight ? theme.font.lineHeight[props.mobilelineheight] : theme.font.lineHeight[props.lineHeight]
      },
      '& .MuiSvgIcon-root': {
        color: (props: IFontProps) => props.iconscolor ? '' : defineColor(props, theme),
        display: 'inline-flex',
        verticalAlign: 'middle',
        paddingBottom: '3px'
      }
    }
  }
}))
