export const CLASS_BASE = () => `${process.env.REACT_APP_BACKEND_URL as string}/classes`

export const CLASS_BASE_WITH_POLICY = () => `${process.env.REACT_APP_BACKEND_URL as string}/withPolicy/classes`

export const CLASS_CREATE = () => `${CLASS_BASE()}`

export const CLASS_GET_ONE = (classId: number) => `${CLASS_BASE_WITH_POLICY()}/${classId}`

export const CLASS_PUT = (classId: number) => `${CLASS_BASE()}/${classId}`

export const CLASS_DISCIPLINE = (classId: number) => `${CLASS_BASE()}/${classId}/disciplines`

export const CLASS_DELETE = (classId: number, gradeId?: number) => {
  const baseUrl = `${CLASS_BASE()}/${classId}`
  const url = new URL(baseUrl)

  if (gradeId) url.searchParams.append('grade_id', gradeId.toString())

  return url.toString()
}

export const CLASS_GET_BY_SCHOOL = (schoolId: number, schoolPeriod: number, page: number, pageSize: number, sort: string, active: number, gradeTypeId?: number, gradeId?: number, search?: string) => {
  const baseUrl = `${CLASS_BASE()}/school/${schoolId}/sortBy/${sort}/page/${page < 0 ? 0 : page}/size/${pageSize}/school-period/${schoolPeriod}/active/${active}`
  const url = new URL(baseUrl)

  if (gradeTypeId) url.searchParams.append('grade_type', gradeTypeId.toString())
  if (gradeId) url.searchParams.append('grade', gradeId.toString())
  if (search) url.searchParams.append('name', search.toString())

  return url.toString()
}
export const CLASS_GET_BY_NAME_AND_SCHOOL_AND_SCHOOL_PERIOD = (className: string, schoolId: number, schoolPeriodId: number) => `${CLASS_BASE()}?name=${className}&school.id=${schoolId}&school_period.id=${schoolPeriodId}`

export const CLASS_GET_STUDENTS_GROUPING_BY_CLASS = () => `${CLASS_BASE()}/getStudentsGroupingByClass`
