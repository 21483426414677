/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line
} from 'recharts'
import { Props as LegendProps } from 'recharts/types/component/DefaultLegendContent'

import { Grid, useTheme, useMediaQuery } from '@mui/material'
import { Alert } from 'components/design-system'
import { ComposedChart } from 'components/common/Charts/ComposedChart'
import { ICustomTooltipProps } from 'components/common/Charts/ComposedChart/types'

import { IEnrollmentDataResponse } from 'services'

import { formatDayMonthLabel } from 'utils'
import { ReactComponent as DottedLine } from './assets/dotted-line.svg'

import useStyle from './styles'

interface INewEnrollmentsChartItem {
  enrollmentData: IEnrollmentDataResponse | undefined
}

export const NewEnrollmentsChartItem: React.FC<INewEnrollmentsChartItem> = ({ enrollmentData }) => {
  const { t } = useTranslation()

  const classes = useStyle()
  const theme = useTheme()

  if (!enrollmentData || enrollmentData == null || !enrollmentData.enrollment.length) {
    return <Alert content={t('Sem dados para exibir')} outlined={true} severity='info' style={{ width: '100%' }} />
  }

  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))

  const reenrollmentDataKey = 'nr_reenrollment'
  const enrollmentDataKey = 'nr_enrollment'
  const student_estimatedDataKey = 'nr_student_estimated'

  const tickStyle = {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.xxs,
    fill: theme.colors.neutral.dark40
  }

  const labelCommonStyle = {
    fontWeight: theme.font.fontWeight.medium,
    fontSize: theme.font.fontSize.xxs
  }

  const renderCustomLegend = ({ payload }: LegendProps): React.ReactElement | null => {
    return (
      <ul className={classes.legendTable}>
        {payload?.map((entry: any, index: number) => (
          <li key={`item-${index}`} style={{ color: entry.color }} className={classes.legendTableItem}>
            {entry.dataKey === student_estimatedDataKey ? (
              <>
                <DottedLine />
                <span style={{ ...labelCommonStyle, marginLeft: '6px' }}>{entry.value}</span>
              </>
            ) : (
              <>
                <span className={classes.legendBullet} style={{ backgroundColor: entry.color }} />
                <span style={{ ...labelCommonStyle, marginLeft: theme.spacingInline.xxxs }}>{entry.value}</span>
              </>
            )}
          </li>
        ))}
      </ul>
    )
  }

  const renderCustomTooltip = ({ active, payload, label }: ICustomTooltipProps) => {
    if (active && payload?.length) {
      return (
        <div className={classes.toolTipContainer}>
          <p className={classes.toolTipTitle}>{`${formatDayMonthLabel(label)}`}</p>
          {
            payload.map(({ name, value }) => (
              <p><span className={classes.toolTipColumnValue}>{value}</span> {name}</p>
            ))
          }
        </div>
      )
    }

    return null
  }

  const formattedEnrollmentData = enrollmentData.enrollment.map(cur => cur).reverse()

  return (
    <Grid container className={classes.chartContainer}>
      <ComposedChart data={formattedEnrollmentData}>
        <defs>
          <linearGradient id={reenrollmentDataKey} x1="0" y1="1" x2="0" y2="0">
            <stop offset="0.0393414" stop-color="white"/>
            <stop offset="1" stop-color="#68B6FF"/>
          </linearGradient>
          <linearGradient id={enrollmentDataKey} x1="0" y1="0" x2="0" y2="1">
            <stop stop-color="#E9C0ED"/>
            <stop offset="1" stop-color="#FFF"/>
          </linearGradient>
        </defs>
        <XAxis dataKey="dt_date" tickFormatter={(tickItem) => formatDayMonthLabel(tickItem) } tickLine={false} tick={isTablet ? false : { ...tickStyle }} />
        <YAxis hide={isTablet} axisLine={false} domain={['auto', 'auto']} tickLine={false} tick={{ ...tickStyle }} />
        <Tooltip cursor={{ fill: 'transparent' }} content={({ active, payload, label }) => renderCustomTooltip({ active, payload, label })} />
        <Legend align='center' iconType='circle' iconSize={8} wrapperStyle={{ fontSize: theme.font.fontSize.xxs, fontWeight: theme.font.fontWeight.medium }} content={renderCustomLegend}/>
        <Area type="monotone" name="rematrículas" dataKey={reenrollmentDataKey} stackId="1" stroke="#42A4FF" strokeWidth={2} fill={`url(#${reenrollmentDataKey})`} />
        <Area type="monotone" name="novas matrículas" dataKey={enrollmentDataKey} stackId="1" stroke="#C65ACE" strokeWidth={2} fill={`url(#${enrollmentDataKey})`} />
        <Line type="bumpX" name="em contrato" dataKey={student_estimatedDataKey} stroke="#3C4E88" strokeWidth={3} dot={false} strokeDasharray="3 4" />
      </ComposedChart>
    </Grid>
  )
}
