import DateAdapter from '@mui/lab/AdapterDateFns'
import MUILocalizationProvider from '@mui/lab/LocalizationProvider'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import { useState } from 'react'
import { DateRange } from '@mui/lab/DateRangePicker'
import { ptBR } from 'date-fns/locale'
import { Grid, Button, Text2, Card } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import makeStyles from './style'
import MUIStaticDateRangePicker, { StaticDateRangePickerProps } from '@mui/lab/StaticDateRangePicker'
import Box from '@mui/material/Box'

export interface ICalendarProps extends Omit<StaticDateRangePickerProps, 'onChange' | 'value' | 'renderInput'> {
  onCancel: any
  onConfirm: any
  onChange: (dates: DateRange<Date>) => void
}

export const Calendar: React.FC<ICalendarProps> = ({ ...props }) => {
  const styles = makeStyles()
  const [value, setValue] = useState<DateRange<Date>>([null, null])
  const { t } = useTranslation()

  const handleOnChange = (newValue: DateRange<Date>) => {
    setValue(newValue)
    props.onChange(newValue)
  }

  const handleConfirmButton = (dates: DateRange<Date>) => {
    if (props.onConfirm) {
      props.onConfirm(dates)
    }
  }

  const handleCancelButton = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }

  return (
    <Card extraClasses={styles.container}>
      <MUILocalizationProvider dateAdapter={DateAdapter} locale={ptBR}>
        <MUIStaticDateRangePicker<Date>
          displayStaticWrapperAs='mobile'
          toolbarTitle='Selecione o período'
          orientation='portrait'
          minDate={new Date()}
          allowSameDateSelection={false}
          startText='Data inicial'
          endText='Data final'
          value={value}
          onChange={(newValue: DateRange<Date>) => {
            handleOnChange(newValue)
          }}
          renderInput={(startProps: TextFieldProps, endProps: TextFieldProps) => (
            <>
              <TextField {...startProps} label='Data inicial' className={styles.textField} />
              <Box sx={{ mx: 2 }}> à </Box>
              <TextField {...endProps} label='Data final' />
            </>
          )}
          className={styles.root}
        />
      </MUILocalizationProvider>
      <Grid container className={styles.buttonGroup}>
        <Grid item>
          <Button variant='ghost' onClick={handleCancelButton}>
            <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xxs' cursor="pointer" colorbrand='medium'>
              {t('Cancelar')}
            </Text2>
          </Button>
        </Grid>
        <Grid item>
          <Button variant='primary' type='submit' disabled={!value[0] || !value[1]} onClick={(_: any) => handleConfirmButton(value)}>
            <Text2 fontSize='xs' fontWeight='semibold' lineHeight='xs' mobile='xxs' cursor="pointer" neutral='lightBase'>
              {t(`${!value[0] || !value[1] ? 'Continuar' : 'Finalizar'}`)}
            </Text2>
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}
