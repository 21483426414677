import useStyles from './style'

interface ResourceCaptionProps {
  source?: string | null
  description?: string | null
}

export const ResourceCaption: React.FC<ResourceCaptionProps> = ({ source, description }) => {
  const classes = useStyles()

  return (
    <div className={classes.captionContainer}>
      {
        (source ?? description)
          ? <span className={classes.caption}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: description ?? ''
              }}
            />
            <strong className={classes.source}>{source ?? ''}</strong></span>
          : ''
      }
    </div>
  )
}
