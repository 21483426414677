import React from 'react'
import { Link, Text2 } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, useTheme } from '@mui/material'
import { CircleButton } from 'components/common'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { UserSchoolProfileTypeEnum } from 'services'
import { USER_MANAGEMENT } from 'navigation/CONSTANTS'
import { useHistory } from 'react-router-dom'

export interface IEditProfileButtonProps {
  profiletype: UserSchoolProfileTypeEnum
  userId: number
  classId?: string
  dataTestId?: string
}

export const EditProfileButton: React.FC<IEditProfileButtonProps> = ({ classId, dataTestId, profiletype, userId }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const theme = useTheme()
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'))

  const handleEditProfileRedirect = () => {
    const prefix = profiletype === UserSchoolProfileTypeEnum.student ? 'students' : 'teachers'
    const _classId = classId ? `/${classId}` : ''
    history.push(USER_MANAGEMENT(`${prefix}/${userId}/edit${_classId}`))
  }

  return isLaptop
    ? (
      <CircleButton
        aria-label='Editar perfil'
        title='Editar perfil'
        data-testid={dataTestId}
        onClick={handleEditProfileRedirect}
      >
        <EditOutlinedIcon />
      </CircleButton>
    )
    : (
      <Text2 fontSize='xxs' fontWeight='semibold' lineHeight='sm' mobile='xxs' colorbrand='dark' cursor="pointer" className='editProfile'>
        <Link
          onClick={handleEditProfileRedirect}
          variant='link'
          nomargin
          data-testid={dataTestId}
        >
          <EditOutlinedIcon sx={{ marginRight: '14px' }} />
          {t('Editar perfil')}
        </Link>
      </Text2>
    )
}
