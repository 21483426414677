import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

const backendOptions = {
  queryStringParams: { v: new Date().toISOString().split('T')[0] }
}

const detectionOptions = {
  order: ['querystring', 'htmlTag', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain']
}

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'pt-BR',
    debug: false,
    detection: detectionOptions,
    interpolation: {
      escapeValue: false
    },
    backend: backendOptions,
    keySeparator: false,
    nsSeparator: false,
    react: {
      useSuspense: false
    }
  })

export default i18n
