import { IDisciplineResponse } from './discipline'

export interface IGrade {
  id: number
  name: string
  grade_type: number
  code: string
  legacy_id: number
  created_at: string
  updated_at: string
}

export interface ISimpleGrade {
  id: number
  name: string
}

export interface IGradeType {
  id: number
  name: string
  code: string
  created_at: string
  updated_at: string
  grades: IGrade[]
}

export interface ISimpleGradeType {
  id: number
  name: string
}

export interface IGradeResponse {
  id: number
  name: string
  code: string
  classes?: IClassesEntity[] | null
}

export interface IGradeTypesResponse {
  name: string
  id: number
  code: string
  grades?: IGradeResponse[]
}

export interface IClassesEntity {
  id: number
  name: string
  disciplines: IDisciplineResponse[] | null
}

export interface IClassGradeTypeWithGrades {
  gradeType: string
  gradeNames: string[]
}

export enum IGradeTypeCodeEnum {
  EI = 'EI',
  EF1 = 'EF1',
  EF2 = 'EF2',
  EM = 'EM',
  EM_IT = 'EM_IT',
  multigrade = 'multigrade'
}

export enum IGradeTypeEnum {
  EI = 1,
  EF1 = 2,
  EF2 = 3,
  EM = 4,
  EM_IT = 5,
  multigrade = 6
}
