import { Dialog, Box, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Icon, Text } from 'components/design-system'

import useStyle from './style'
import { useTranslation } from 'react-i18next'

interface IModalWrapperProps {
  open: boolean
  confirmLabel?: string
  cancelLabel?: string
  onCancel?: () => void
  onConfirm: () => void
  confirmDatatestId?: string
  cancelDatatestId?: string
  closeDatatestId?: string
}

export const ModalWrapper: React.FC<IModalWrapperProps> = ({ open, onCancel, onConfirm, children, confirmDatatestId, cancelDatatestId, confirmLabel, cancelLabel, closeDatatestId }) => {
  const classes = useStyle()
  const theme = useTheme()
  const { t } = useTranslation()

  const handleCancel = () => {
    if (!onCancel) {
      onConfirm()
      return
    }
    onCancel()
  }
  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <Dialog onClose={handleCancel} open={open} className={classes.modalContainer}>
      <Box className={classes.modalHeader}>
        <Box className={classes.closeContainer} onClick={handleCancel} data-testid={closeDatatestId ?? cancelDatatestId}>
          <Icon titleAccess={t('Ícone de Fechar')} size='medium' iconColor={theme.colorBrand.medium}>
            <CloseIcon />
          </Icon>
          <Text className={classes.headerText}>
            {t('Fechar')}
          </Text>
        </Box>
      </Box>
      <Box className={classes.modalBody}>
        <>{children}</>
      </Box>
      <Box className={classes.modalFooter}>
        {!!onCancel && (
          <Button
            variant='ghost'
            onClick={handleCancel}
            data-testid={cancelDatatestId}
          >
            {cancelLabel ?? t('Cancelar')}
          </Button>
        )}
        <Button
          variant='primary'
          onClick={handleConfirm}
          data-testid={confirmDatatestId}
        >
          {confirmLabel ?? t('Confirmar')}
        </Button>
      </Box>
    </Dialog>
  )
}
export default ModalWrapper
