/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, createRef, useEffect } from 'react'
import { useStore } from 'store'
import Analytics from 'utils/analytics'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import { Icon } from 'components/design-system'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'

import useStyle from './style'
import { Tag } from '../Tag'
import { ISchoolDisciplinesResponse } from 'services'

interface ISegmentSelector {
  dataTestid?: string
  taughtDisciplines: ISchoolDisciplinesResponse[]
  title?: string
  onChange?: (segmentId: number) => void
  selected?: number
  disabled?: boolean
}

export const Segments: React.FC<ISegmentSelector> = (props: ISegmentSelector) => {
  const { taughtDisciplines, title, onChange, dataTestid, selected: firstSelection, disabled } = props
  const classes = useStyle()
  const [dropDown, setDropDown] = useState<boolean>(!!title)
  const [selected, setSelected] = useState<number>(firstSelection ?? 1)
  const { t } = useTranslation()
  const inputRef = createRef<HTMLDivElement>()
  const theme = useTheme()
  const segmentRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (!selected || !taughtDisciplines) return

    const availableIds = taughtDisciplines.map(el => el.id)

    if (!availableIds.includes(selected)) {
      handleSegmentChange(taughtDisciplines?.[0]?.id)
    }
  }, [taughtDisciplines, selected])

  const handleSegmentChange = (segmentId: number): void => {
    setSelected(segmentId)
    void onChange?.(segmentId)
    segmentRef?.current?.click()
  }

  return (
    <>
      <div ref={segmentRef} data-testid={dataTestid} hidden />

      {!!title && (
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            {title}
          </div>
          <div
            className={classes.titleDrop}
            onClick={() => setDropDown(!dropDown)}
            id='see_all_mobile'
          >
            <p>{t('Ver todos')}</p>
            {dropDown
              ? <Icon titleAccess={t('Ver todos')} size='medium'>
                <KeyboardArrowUpOutlinedIcon />
              </Icon>
              : <Icon titleAccess={t('Recolher todos')} size='medium'>
                <KeyboardArrowDownOutlinedIcon />
              </Icon>
            }
          </div>
        </div>
      )}

      <div ref={inputRef} data-testid={dataTestid} hidden />

      <div className={`${classes.tagsContainer} ${!dropDown && 'active'}`}>
        {
          taughtDisciplines?.map(segment =>
            <Tag
              key={segment?.id}
              className={`${classes.tag}`}
              color={segment?.id === selected ? theme.colors.neutral.lightBase : undefined}
              background={segment?.id === selected ? theme.colorBrand.medium : undefined}
              disabled={disabled}
              onClick={() => {
                inputRef?.current?.click()
                handleSegmentChange(segment?.id)
              }}
            >
              {t(segment?.name ?? '')}
            </Tag>
          )
        }
      </div>
    </>
  )
}
