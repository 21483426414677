import { ComposedChart as ReachartComposedChart, ResponsiveContainer } from 'recharts'

interface IComposedChartProps {
  data: any[]
}

export const ComposedChart: React.FC<IComposedChartProps> = ({ data, children }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ReachartComposedChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 0
        }}
      >
        {children}
      </ReachartComposedChart>
    </ResponsiveContainer>
  )
}
