import useStyles from './style'
import { ResourceCaption } from 'components/common'
export interface IVideoPlayerProps {
  url: string
  source?: string | null
  description?: string | null
}

const convertVideoUrl = (input: string, source: string | null | undefined, description: string | null | undefined) => {
  const classes = useStyles()

  const pattern = /([-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?(?:webm|mp4|ogv))/gi
  if (pattern.test(input)) {
    const replacement = '<video controls="" loop="" controls src="$1" title="videoPlayer" ></video>'
    input = input.replace(pattern, replacement)
  }
  return (
    <div style={{ width: '100%' }}>
      <div className={classes.video}
        dangerouslySetInnerHTML={{
          __html: input ?? ''
        }}
      />
      <ResourceCaption source={source} description={description}></ResourceCaption>
    </div>
  )
}

export const VideoPlayer: React.FC<IVideoPlayerProps> = ({ url, source, description }) => {
  return (
    convertVideoUrl(url, source, description)
  )
}
