import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import useStyles from './style'

export interface ICheckbox extends CheckboxProps {
  customClasses?: string
  dataTestid?: string
  label: string
}

export const Checkbox: React.FC<ICheckbox> = ({ customClasses, dataTestid, label, ...props }) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      className={`${classes.root} ${customClasses ?? ' '}`}
      control={
        <MuiCheckbox
          {...props}
          className={`${classes.root}`}
          data-testid={dataTestid}
          disableRipple
        />
      }
      label={label}
    />
  )
}
