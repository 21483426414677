import useStyles from './style'
import { ResourceCaption } from 'components/common'
import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { Alert } from 'components/design-system'
import { DownloadOutlined, PictureAsPdfOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

interface PdfRenderProps {
  url: string
  source?: string | null
  description?: string | null
}

export const PdfRender: React.FC<PdfRenderProps> = ({ url, source, description }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isiPhoneiPad = /iPhone|iPad/i.test(navigator.userAgent)
  const sourceUrl = isiPhoneiPad ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(url)}` : `${url}#view=fit`

  if (isMobileOrTablet) {
    return (
      <div style={{ width: '100%' }}>
        <Alert
          content={t('Caso não consiga visualizar, baixe o arquivo ou tente trocar de navegador.')}
          severity='warning'
          className={classes.movWarning}
          style={{ maxWidth: '1000px' }}
        />
        <Grid className={classes.contentDownload}>
          <span>{source}</span>
          <DownloadOutlined onClick={() => window.open(url, '_blank')} />
        </Grid>
        <div className={classes.pdf}>
          <iframe src={sourceUrl} height='800px'></iframe>
          <Grid position='absolute' ><PictureAsPdfOutlined fontSize='large' /></Grid>
        </div>
        <ResourceCaption source={source} description={description}></ResourceCaption>
      </div>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.pdf}>
        <iframe src={sourceUrl} height='800px'></iframe>
      </div>
      <ResourceCaption source={source} description={description}></ResourceCaption>
    </div>
  )
}
